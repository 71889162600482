import {
  ADD_ADMIN,
  ADD_USER,
  ADD_COORDINATOR,
  ADD_COACH,
  LOGIN_ADMIN,
  DELETE_USER,
  ERROR,
  GET_ROLES,
  GET_USERS,
  GET_COACH,
  GET_COORDINATOR,
  GET_ADMIN,
  LOGOUT,
  CLEAR_ERROR,
  SET_LOADING,
  CLEAR_LOADING,
  DELETE_COORDINATOR,
  DELETE_ADMIN,
  DELETE_COACH,
} from "../Actions/Admin";

const intitialState = {
  adminData: null,
  token: null,
  userData: [],
  coordinatorData: [],
  adminsList: [],
  coachData: [],
  error: null,
  roles: [],
  loading: false,
};

export default (state = intitialState, action) => {
  switch (action.type) {
    case LOGIN_ADMIN:
      return {
        ...state,
        adminData: action.payload.user,
        token: action.payload.token,
      };
    case ADD_ADMIN:
      return {
        ...state,
      };
    case GET_USERS:
      return {
        ...state,
        userData: action.payload.data,
      };
    case GET_COORDINATOR:
      return {
        ...state,
        coordinatorData: action.payload.data,
      };
    case GET_ADMIN:
      return {
        ...state,
        adminsList: action.payload.data,
      };
    case GET_COACH:
      return {
        ...state,
        coachData: action.payload.data,
      };
    case GET_ROLES:
      return {
        ...state,
        roles: action.payload.data,
      };
    case ADD_USER:
      return {
        ...state,
        userData: [...state.userData, action.payload.data],
      };
    case ADD_COACH:
      return {
        ...state,
        coachData: [...state.coachData, action.payload.data],
      };
    case ADD_COORDINATOR:
      return {
        ...state,
        coordinatorData: [...state.coordinatorData, action.payload.data],
      };
    case DELETE_USER:
      return {
        ...state,
        userData: state.userData.filter((item) => item._id !== action.payload),
      };
    case DELETE_COORDINATOR:
      return {
        ...state,
        coordinatorData: state.coordinatorData.filter(
          (item) => item._id !== action.payload
        ),
      };
    case DELETE_ADMIN:
      return {
        ...state,
        adminsList: state.adminsList.filter(
          (item) => item._id !== action.payload
        ),
      };
    case DELETE_COACH:
      return {
        ...state,
        coachData: state.coachData.filter((item) => item._id !== action.payload),
      };
    case ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case CLEAR_ERROR: {
      return {
        ...state,
        error: null,
      };
    }
    case LOGOUT:
      return {
        ...intitialState,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CLEAR_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
